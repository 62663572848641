import styled from '@emotion/styled';

export const HeaderWrapper = styled.div`
  background-color: ${(props) => props.theme.backgroundColor};
  font-size: 24px;
  color: black;
  font-weight: bold;
  text-align: center;
  min-height: 10vh;
`;
