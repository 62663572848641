import styled from '@emotion/styled';

export const EditorWrapper = styled.div`
  font-size: 24px;
  color: black;
  font-weight: bold;
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  position: relative;
  width: 90vw;
  background-color: ${(props) => props.theme.backgroundColor};
`;

export const EditorComponent = styled.div`
  flex: 1;
  border: 2px solid ${(props) => props.theme.backgroundColor};
  position: relative;
  overflow: scroll;
  border-radius: 10px;
  background-color: ${(props) => props.theme.offGrey};
`;
