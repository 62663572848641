import styled from '@emotion/styled';

export const FooterWrapper = styled.div`
  background-color: ${(props) => props.theme.backgroundColor};
  font-size: 24px;
  color: black;
  text-align: center;
  min-height: 8vh;
  line-height: 8vh;
  font-family: 'Bebas Neue';
`;
