import React, { useState, useEffect } from 'react';
import Editor from 'react-simple-code-editor';
import yaml from 'js-yaml';
import { ScrollSync, ScrollSyncPane } from 'react-scroll-sync';
import { highlight, languages } from 'prismjs/components/prism-core';
import { EditorWrapper, EditorComponent } from './style';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-json';
import 'prismjs/components/prism-javascript';
import 'prismjs/components/prism-yaml';
import 'prismjs/themes/prism.css';

export const Editors = () => {
  const [jsonCode, setJsonCode] = useState(
    '{\n  "json": "yaml",\n  "yaml": "json"\n}',
  );

  const [yamlCode, setYamlCode] = useState(
    'json: yaml\nyaml: json',
  );
  const [isJsonError, setIsJsonError] = useState(false);
  const [isYamlError, setIsYamlError] = useState(false);
  const [isChanging, setIsChanging] = useState(false);

  const onJsonChange = (code) => {
    setJsonCode(code);
    setIsChanging(true);
  };

  const onYamlChange = (code) => {
    setYamlCode(code);
    setIsChanging(true);
  };

  const clearErros = () => {
    setIsJsonError(false);
    setIsYamlError(false);
  };

  useEffect(() => {
    if (isChanging) {
      try {
        const doc = yaml.dump(JSON.parse(jsonCode));
        if (!doc) {
          return;
        }
        clearErros();
        setYamlCode(doc);
      } catch (e) {
        if (jsonCode.length > 0) {
          setIsJsonError(true);
          return;
        }
        return;
      }
      setIsChanging(false);
    }
  }, [jsonCode]);

  useEffect(() => {
    if (isChanging) {
      try {
        const doc = yaml.load(yamlCode);
        if (!doc) {
          setJsonCode('');
          return;
        }
        clearErros();
        setJsonCode(JSON.stringify(doc, undefined, 2));
      } catch (e) {
        setIsYamlError(true);
        return;
      }
      setIsChanging(false);
    }
  }, [yamlCode]);

  return (
    <ScrollSync>
      <EditorWrapper className="wrapper">
        <ScrollSyncPane>
          <EditorComponent
            className="jsonComponent"
            style={{
              outline: isJsonError && '2px solid red',
            }}
          >
            <Editor
              autoFocus
              value={jsonCode}
              className="language-json"
              lang="json"
              onValueChange={(code) => onJsonChange(code)}
              highlight={(code) => highlight(code, languages.js)}
              padding={10}
              style={{
                fontFamily: '"Fira code", "Fira Mono", monospace',
                fontSize: 12,
                outline: isJsonError && 'red 2px solid',
              }}
              as="textarea"
            />
          </EditorComponent>
        </ScrollSyncPane>

        <ScrollSyncPane>
          <EditorComponent
            className="yamlComponent"
            style={{
              outline: isYamlError && '2px solid red',
            }}
          >
            <Editor
              value={yamlCode}
              className="language-yaml"
              lang="yaml"
              onValueChange={(code) => onYamlChange(code)}
              highlight={(code) => highlight(code, languages.yaml)}
              padding={10}
              style={{
                fontFamily: '"Fira code", "Fira Mono", monospace',
                fontSize: 12,
              }}
            />
          </EditorComponent>
        </ScrollSyncPane>
      </EditorWrapper>
    </ScrollSync>
  );
};
