import { ThemeProvider } from '@emotion/react';
import { Header } from './components/Header';
import { Footer } from './components/Footer';
import { MainDisplay } from './components/MainDisplay';

const theme = {
  backgroundColor: '#81d4fa',
  offGrey: '#ebebeb',
};

const App = () => (
  <ThemeProvider theme={theme}>
    <Header />
    <MainDisplay />
    <Footer />
  </ThemeProvider>
);

export default App;
