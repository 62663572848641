import React from 'react';
import { FooterWrapper } from './style';

const Footer = () => (
  <FooterWrapper className="footer">
    <p>
      This tool was creted by 2 nerds.
    </p>
  </FooterWrapper>
);

export { Footer };
