import React from 'react';
import { MainDisplayStyle } from './style';
// import { Ads } from '../Ads';
import { Editors } from '../Editor';

const MainDisplay = () => (
  <MainDisplayStyle>
    <Editors />
  </MainDisplayStyle>
);

export { MainDisplay };
